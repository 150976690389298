/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React from "react";
import Main from "./pages/Main";

import StandardAppContainer from "./Standard/StandardAppContainer";

export const App = () => {
  return (
      <StandardAppContainer forcedLocale={'ru'}>
          <Main />
      </StandardAppContainer>
  );
};
