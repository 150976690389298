import React, {useContext, useEffect, useState} from "react";
import texts from './localization'
import LocaleContext from "../../Standard/LocaleContext";
import {localized} from "../../Standard/utils/localized";
import SimpleValidatedInput from "../../components/SimpleValidatedInput";
import {useEmailCollectorContract} from "../../hooks /useEmailCollectorContract";
import Spinner from "../../Standard/components/Spinner";

import styled from 'styled-components'
import {useWeb3React} from "@web3-react/core";

interface ButtonProps {
  background: string
  textColor: string
}


type MainPropType = {}

const MainDefaultProps = {}

const testEmailRegex = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;

  @media (max-width: 550px) {
    font-size: 16px;
  }
`

const MainTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  
  @media (max-width: 550px) {
    font-size: 24px;
  }
`

const Description = styled.div`
  max-width: 600px;
  width: 100%;
  font-size: 18px;
  text-align: center;
  margin-bottom: 40px;

  @media (max-width: 550px) {
    font-size: 14px;
  }
`

const Button = styled.button<ButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 50px;
  background: #33CC66;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  color: ${p => p.textColor};
  background: ${p => p.background};
  outline: none;
  transition: background 0.3s ease;
  margin-top: 10px;

  &:focus,
  &:active {
    outline: none;
  }
`

const Link = styled.a`
  text-decoration: underline;
`

const Attention = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: red;
`


const Main = (props: MainPropType) => {
  const {locale} = useContext(LocaleContext)
  const {account} = useWeb3React()

  const [email, setEmail] = useState<string | undefined>(undefined)
  const [emailValid, setEmailValid] = useState(false)
  const [isModalActive, setIsModalActive] = useState(false)
  const [isAssociate, setIsAssociate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const emailCollectorContract = useEmailCollectorContract()

  async function encryptEmail(email: string): Promise<{ encryptedEmail: string }> {
    const encryptEmailURL = 'https://encrypted-email-mmpro.herokuapp.com/encryptEmail'
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({email: email})
    };
    return fetch(encryptEmailURL, requestOptions)
      .then(response => response.json())
  }

  async function sendEmail() {
    if (emailValid && account && email) {
      setIsLoading(true)
      const {encryptedEmail} = await encryptEmail(email)
      await emailCollectorContract
        .methods
        .associate(encryptedEmail)
        .send({from: account}).once('receipt', () => {
          setEmail('')
          setIsLoading(false)
          setIsAssociate(true)
        });
    }
  }

  async function checkAssociate(account: string) {
    const isAssociate = await emailCollectorContract
      .methods
      .isAssociated(account)
      .call()
    setIsAssociate(isAssociate)
  }

  useEffect(() => {
    if (account) {
      checkAssociate(account)
    }
  }, [account])

  return (
    <MainContainer>
      <MainTitle>Using this service, you will be able to attach your email to your personal wallet.</MainTitle>
      {isAssociate ?
        <Description>
          <Title>Thanks for attaching your email to your wallet!</Title>
          <Title>
            Please be patient, our team processing your request, and soon you will be able to see shares from Launchpad
            at your Zimabank account.
          </Title>
        </Description>
        :
        <>
          {
            account ?
              <>
                <Description>
                  <Attention>Attention!</Attention>
                  <br/>
                  Please make sure that you are entering SAME email that you are using on Zimabank account.
                  If you did not registered yet, please first use <Link href="https://remotebankingaccess.com/sign-up" target="_blank">that link</Link> for registration:
                </Description>
                <SimpleValidatedInput
                  className="w-full"
                  onChange={(e) => setEmail(e.target.value)}
                  onValidationChange={(isValid) => setEmailValid(isValid)}
                  validationFunction={(text) => testEmailRegex.test(text)}
                  errorTooltipText={'Please enter a correct email'}
                  placeholder="Email"
                  type="email"
                  autocomplete="email"
                />
                <Button
                  textColor={emailValid ? '#fff' : 'rgba(255, 255, 255, 0.6)'}
                  background={emailValid ? '#33CC66' : 'rgba(0, 0, 0, 0.2)'}
                  onClick={sendEmail}
                >
                  {
                    isLoading ?
                      <Spinner color={'white'} size={isLoading ? 25 : 0}/>
                      :
                      "Send email"
                  }
                </Button>
              </>
              :
              <Description>
                Please connect your wallet to continue.
                <br/>
                <br/>
                <Attention>Attention!</Attention>
                <br/>
                Please only connect wallet that you have used for the Launchpad’s projects participation. Connect wrong
                wallet will cause empty shares amount on the Zimabank account.
              </Description>
          }
        </>
      }
    </MainContainer>
  )
};

Main.defaultProps = MainDefaultProps

export default Main

